// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Dil çevirileriniz
const resources = {
  en: {
    translation: {
      "login": "Login",
      "register": "Register",
      "invalidEmail": "Invalid email address.",
      "passwordRequirement": "Password must be at least 6 characters.",
    }
  },
  tr: {
    translation: {
      "login": "Giriş Yap",
      "register": "Kayıt Ol",
      "invalidEmail": "Geçersiz e-posta adresi.",
      "passwordRequirement": "Şifre en az 6 karakter olmalıdır.",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || "en", // Default to Turkish or stored language
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
