// import React, { createContext, useContext, useEffect, useState } from 'react';
// import i18n from './i18n';

// const LanguageContext = createContext();

// export const useLanguage = () => useContext(LanguageContext);

// export const LanguageProvider = ({ children }) => {
//   const [language, setLanguage] = useState(i18n.language);

//   useEffect(() => {
//     const storedLang = localStorage.getItem('i18nextLng') || 'en';
//     i18n.changeLanguage(storedLang);
//     setLanguage(storedLang);
//   }, []);

//   const changeLanguage = (lang) => {
//     i18n.changeLanguage(lang).then(() => {
//       localStorage.setItem('i18nextLng', lang);
//       setLanguage(lang);  // React state güncellemesi
//     });
//   };

//   return (
//     <LanguageContext.Provider value={{ language, changeLanguage }}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };

// LanguageContext.js
/* import React, { createContext, useContext, useEffect, useState } from 'react';
import i18n from './i18n';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng') || 'en';
    if (i18n.language !== storedLang) {
      i18n.changeLanguage(storedLang);
    }
    setLanguage(storedLang);
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}; */

// LanguageContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import i18n from './i18n';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const syncLang = () => {
      const storedLang = localStorage.getItem('i18nextLng') || 'en';
      if (i18n.language !== storedLang) {
        i18n.changeLanguage(storedLang);
      }
      setLanguage(storedLang);
    };

    syncLang();
    window.addEventListener('languagechange', syncLang);

    return () => {
      window.removeEventListener('languagechange', syncLang);
    };
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
