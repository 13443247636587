import React, { useEffect, useState } from 'react';
import { useSelector, Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { store } from './redux/store';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setUser, clearUser, setLoading } from './redux/userSlice';
import SpinWheel from './pages/spin_wheel/SpinWheel';
import AdminPanel from './pages/admin/AdminPanel';
import WheelEdit from './pages/admin/WheelEdit';
import UserList from './pages/admin/UserList';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import { LanguageProvider } from './localization/LanguageContext';
import UserAgreement from './pages/UserAgreement';
import UnderMaintenance from './pages/under_maintenance/UnderMaintenance';
import { doc, getDoc } from "firebase/firestore";
import { db } from './firebase/firebase';
import LoadingPage from './LoadingPage';
import ForgotPassword from './pages/auth/ForgotPassword';

function App() {
  const { user, isLoading } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    dispatch(setLoading(true));
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userRef = doc(db, "users", firebaseUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          dispatch(setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            isAdmin: userData.isAdmin,
          }));
        } else {
          console.error("User document not found in Firestore");
        }
      } else {
        dispatch(clearUser());
      }
      dispatch(setLoading(false)); 
    });
  
    return () => unsubscribe();
  }, [dispatch]);
  

  useEffect(() => {
    const fetchSiteConfig = async () => {
      const configRef = doc(db, "settings", "siteConfig");
      const configSnap = await getDoc(configRef);
      if (configSnap.exists()) {
        setIsUnderMaintenance(configSnap.data().isSiteOff);
      } else {
        console.error("No site configuration found!");
      }
    };

    fetchSiteConfig();
  }, []);

  return (
    <Provider store={store}>
      <LanguageProvider>
        <Router>
          <Routes>
            {isUnderMaintenance ? (
              <Route path="*" element={<UnderMaintenance />} />
            ) : isLoading ? (
              <Route path="*" element={<LoadingPage />} />
            ) : (
              <>
                <Route path="/login" element={!user ? <Login /> : (user.isAdmin ? <Navigate replace to="/admin" /> : <Navigate replace to="/spin-wheel" />)} />
                <Route path="/register" element={!user ? <Register /> : <Navigate replace to="/spin-wheel" />} />
                <Route path="/forgot-password" element={!user ? <ForgotPassword /> : <Navigate replace to="/spin-wheel" />} />
                <Route path="/spin-wheel" element={user ? <SpinWheel /> : <Navigate replace to="/login" />} />
                <Route path="/admin" element={user && user.isAdmin ? <AdminPanel /> : <Navigate replace to="/login" />} />
                <Route path="/edit-wheel" element={user && user.isAdmin ?  <WheelEdit /> : <Navigate replace to="/login" />} />
                <Route path="/user-list" element={user && user.isAdmin ?  <UserList /> : <Navigate replace to="/login" />} />
                <Route path="/user-agreement" element={<UserAgreement />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
              </>
            )}
          </Routes>
        </Router>
      </LanguageProvider>
    </Provider>
  );
}

export default App;
