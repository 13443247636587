  class Person {
    constructor(id, email, fullName, phoneNumber, birthDay, spins, createdAt, isAdmin = false, countryCode, spinCount = 1) {
      this.id = id;
      this.email = email;
      this.fullName = fullName;
      this.phoneNumber = phoneNumber;
      this.birthDay = birthDay;
      this.spins = spins || [];
      this.createdAt = createdAt;
      this.isAdmin = isAdmin;
      this.countryCode = countryCode;
      this.spinCount = spinCount;
    }

    addSpin(spin) {
        if (this.spins.length <= this.spinCount) {
            this.spins.push(spin);
        } else {
            console.log("Maximum spin count reached");
        }
    }
}

export default Person;