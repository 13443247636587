import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDoc, getDocs, doc, updateDoc} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleMenu, setVisibleMenu] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, userId: null, action: null });
  const navigate = useNavigate();
  const [spinCount, setSpinCount] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      const q = collection(db, "users");
      const querySnapshot = await getDocs(q);
      const userList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(userList);
    };

    fetchUsers();
  }, []);

  const makeAdmin = async (userId) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { isAdmin: true });
    setConfirmDialog({ ...confirmDialog, isOpen: false }); 
  };

  const removeAdmin = async (userId) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { isAdmin: false });
    setConfirmDialog({ ...confirmDialog, isOpen: false }); 
  };

  const handleIncrement = () => {
    setSpinCount(prevCount => prevCount + 1);
  };

  const handleDecrement = () => {
    setSpinCount(prevCount => prevCount - 1 ); 
  };

  const giveSpinRight = async (userId) => {
    const userRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      await updateDoc(userRef, {
        spinCount: userData.spinCount + spinCount
      });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false }); 
  };

  const handleConfirmAction = async () => {
    if (confirmDialog.action === 'makeAdmin') {
      await makeAdmin(confirmDialog.userId);
    } else if (confirmDialog.action === 'giveSpinRight') {
      await giveSpinRight(confirmDialog.userId);
    } else if (confirmDialog.action === 'removeAdmin') {
      await removeAdmin(confirmDialog.userId);
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };


  const showConfirmDialog = (action, userId) => {
    setSpinCount(0);
    setConfirmDialog({ isOpen: true, action: action, userId: userId });
    setVisibleMenu(null);
  };

  const toggleMenu = (userId) => {
    setVisibleMenu(prevVisibleMenu => prevVisibleMenu === userId ? null : userId);
  };

  const filteredUsers = users.filter(user => {
    return user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
           user.phoneNumber.includes(searchTerm) ||
           user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
           (user.spins && user.spins.some(spin => spin.resultName.toLowerCase().includes(searchTerm.toLowerCase())));
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full pt-16 max-w-md px-6 py-8">
        <button 
          onClick={() => navigate(-1)} 
          className="absolute top-0 left-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4"
        >
          Geri Git
        </button>
        <input
          type="text"
          placeholder="Arama yap (İsim, E-posta, Numara veya sonuç)"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 w-full mb-4 focus:border-black focus:ring-black focus:outline-none"
        />
        <div className="overflow-y-auto max-h-96 grid gap-4">
          {filteredUsers.map(user => (
            <div key={user.id} className="bg-white p-4 shadow relative">
              <button className="absolute top-5 right-5 text-xl" onClick={() => toggleMenu(user.id)}>
                ⋮
              </button>
              {visibleMenu === user.id && (
                <ul className="bg-white shadow rounded-md absolute top-8 right-1 w-40">
                  <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => showConfirmDialog('makeAdmin', user.id)}>Admin yap</li>
                  {user.isAdmin && (
      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => showConfirmDialog('removeAdmin', user.id)}>Admin kaldır</li>
    )}
                  <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer"onClick={() => showConfirmDialog('giveSpinRight', user.id)}>Çark hakkı ver</li>
                </ul>
              )}
              <h2 className="text-lg font-semibold text-gray-900">{user.fullName}{user.isAdmin ? " - Admin" : ""}</h2>
              <span className="font-semibold">Çark Sonuçları:</span>
              {user.spins && user.spins.map((spin, index) => (
            <p key={index} className="text-gray-700">
             
             <span className="font-semibold">{index+1}. Çark:</span> {spin.resultName}{/*  - Çevirdiği tarih: {spin.createdAt} - Son tarih: {spin.expiredAt} */}
             <br/>
             <span className="font-semibold">Çeviri tarihi: </span> {spin.createdAt}
             <br/>
             <span className="font-semibold">Son kullanım tarihi:</span> {spin.expiredAt}
            </p>
          ))}
              <p className="text-gray-700"><span className="font-semibold">E-posta:</span> {user.email}</p>
              <p className="text-gray-700"><span className="font-semibold">Numara:</span> {user.phoneNumber}</p>
              <p className="text-gray-700"><span className="font-semibold">Doğum Tarihi:</span> {user.birthDay}</p>
              <p className="text-gray-700"><span className="font-semibold">Yaratıldı:</span> {user.createdAt}</p>
              <p className="text-gray-700"><span className="font-semibold">Çevirme hakkı:</span> {user.spinCount}</p>
            </div>
          ))}
        </div>
        {confirmDialog.isOpen && confirmDialog.action === 'makeAdmin' && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <p className="text-lg">İşlemi Onaylıyor musun?</p>
              <div className="flex flex-col mt-4">
                <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={handleConfirmAction}>Evet</button>
                <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>Hayır</button>
              </div>
            </div>
          </div>
        )}
        {confirmDialog.isOpen && confirmDialog.action === 'removeAdmin' && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <p className="text-lg">İşlemi Onaylıyor musun?</p>
              <div className="flex flex-col mt-4">
                <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={handleConfirmAction}>Evet</button>
                <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>Hayır</button>
              </div>
            </div>
          </div>
        )}
      </div>
      {confirmDialog.isOpen && confirmDialog.action === 'giveSpinRight' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <p className="text-lg">Kaç çark hakkı vermek istiyorsunuz?</p>
            <div className="flex items-center justify-center mt-4">
              <button className="px-4 py-2 bg-gray-200" onClick={handleDecrement}>-</button>
              <span className="mx-4 text-lg">{spinCount}</span>
              <button className="px-4 py-2 bg-gray-200" onClick={handleIncrement}>+</button>
            </div>
            <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={() => giveSpinRight(confirmDialog.userId)}>Onayla</button>
            <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>İptal</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
