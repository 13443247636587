
import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase';
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const AddItemModal = ({ onSave, onClose, editItem }) => {
  const [itemName, setItemName] = useState(editItem ? editItem.itemName : '');
  const [itemNameEn, setItemNameEn] = useState(editItem ? editItem.itemNameEn : '');
  const [chance, setChance] = useState(editItem ? editItem.chance : '');
  const [duration, setDuration] = useState(editItem ? editItem.duration : '');

  const itemNameEnRef = useRef(null);
  const chanceRef = useRef(null);
  const durationRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSave(itemName, itemNameEn, Number(chance), editItem ? editItem.id : null, Number(duration));
  };

  const handleKeyDown = (event, focusRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      focusRef.current && focusRef.current.focus();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-5 max-w-sm w-full">
        <form onSubmit={handleSubmit} className="space-y-4">
        <p className="text-gray-700 text-xs">Seçenek Adı (TR)</p>
          <input
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            type="text"
            placeholder="Seçenek Adı (TR)"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, itemNameEnRef)}
          />
          <p className="text-gray-700 text-xs">Option Name (EN)</p>
          <input
            ref={itemNameEnRef}
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            type="text"
            placeholder="Option Name (EN)"
            value={itemNameEn}
            onChange={(e) => setItemNameEn(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, durationRef)}
          />
          <p className="text-gray-700 text-xs">Çıkma Olasılığı (%)</p>
          <input
            ref={durationRef}
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            type="number"
            placeholder="Çıkma Olasılığı (%)"
            value={chance}
            onChange={(e) => setChance(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, chanceRef)}
          />
          <p className="text-gray-700 text-xs">Geçerlilik süresi (Gün)</p>
          <input
            ref={chanceRef}
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            type="number"
            placeholder="Geçerlilik süresi (Gün)"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
          />
          <button type="submit" className="w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white">Kaydet</button>
          <button type="button" onClick={onClose} className="w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white">İptal</button>
        </form>
      </div>
    </div>
  );
};

const WheelEdit = () => {
  const [items, setItems] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, itemId: null });


  const showConfirmDialog = (itemId) => {
    setConfirmDialog({ isOpen: true, itemId: itemId });
  };
  
  const handleConfirmDelete = async () => {
    await handleDeleteItem(confirmDialog.itemId);
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };
  

  const fetchItems = async () => {
    const querySnapshot = await getDocs(collection(db, "wheel_items"));
    const itemList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setItems(itemList);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleAddOrEditItem = async (itemName, itemNameEn, chance, id, duration) => {
    if (id) {
      await updateDoc(doc(db, "wheel_items", id), { itemName, itemNameEn, chance, duration });
    } else {
      const docRef = await addDoc(collection(db, "wheel_items"), { itemName, itemNameEn, chance, duration });
      await updateDoc(doc(db, "wheel_items", docRef.id), {
        uid: docRef.id
      });
    }
    fetchItems();
    setShowAddModal(false);
    setCurrentEditItem(null);
  };

  const handleDeleteItem = async (itemId) => {
    await deleteDoc(doc(db, "wheel_items", itemId));
    fetchItems();
  };

  const handleEditClick = (item) => {
    setCurrentEditItem(item);
    setShowAddModal(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <button 
          onClick={() => navigate(-1)} 
          className="absolute top-0 left-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4 z-10"
        >
          Geri Git
        </button>
        <div className="pt-16 px-4 w-full">
        {items.map((item) => (
          <div key={item.id} className="flex flex-col sm:flex-row justify-between items-center py-2 px-4 w-full">
            <div className="flex flex-col sm:flex-row justify-start items-center flex-grow mb-2 sm:mb-0">
              <span className="flex-1 mb-2 sm:mb-0">Seçenek TR: {item.itemName}</span>
              <span className="flex-1 mb-2 sm:mb-0">Seçenek EN: {item.itemNameEn}</span>
              <span className="flex-1 mb-2 sm:mb-0">Geçerlilik süresi (Gün): {item.duration}</span>
              <span className="flex-1 mb-2 ml-4">Şans: {item.chance}%</span>
            </div>
            <div className="flex justify-center w-full sm:w-auto">
              <button onClick={() => handleEditClick(item)} className="bg-white text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white font-bold py-2 px-4 mr-2">
                Düzenle
              </button>
            <button onClick={() => showConfirmDialog(item.id)} className="bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white font-bold py-2 px-4">
  Sil
</button>

            </div>
          </div>
        ))}
        </div>
        <button className="mt-4 w-full max-w-sm px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline"  onClick={() => { setShowAddModal(true); setCurrentEditItem(null); }}>+ Ekle</button>
      {showAddModal && (
        <AddItemModal onSave={handleAddOrEditItem} onClose={() => { setShowAddModal(false); setCurrentEditItem(null); }} editItem={currentEditItem} />
      )}
      {confirmDialog.isOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <p className="text-lg">Bu öğeyi silmek istediğinize emin misiniz?</p>
      <div className="flex justify-around mt-4">
        <button
          className="w-32 px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline"
          onClick={handleConfirmDelete}
        >
          Evet
        </button>
        <button
          className="w-32 px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline"
          onClick={() => setConfirmDialog({ isOpen: false, itemId: null })}
        >
          Hayır
        </button>
      </div>
    </div>
  </div>
)}

    </div>
    
  );
};

export default WheelEdit;
