import React from 'react';
import logo from '../../assets/images/hyggeLogo.png';
import { useLanguage } from '../../localization/LanguageContext';

const UnderMaintenance = () => {
  const { language } = useLanguage();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <img src={logo} alt="Logo" />
      <h1>{language === 'en' ? 'Our Site is Currently Under Maintenance' : 'Sitemiz Şu Anda Bakımdadır'}</h1>
      <p>{language === 'en' ? 'Please try again later.' : 'Lütfen daha sonra tekrar deneyiniz.'}</p>
    </div>
  );
};

export default UnderMaintenance;
