import React from 'react';
import { useLanguage } from './../localization/LanguageContext';

const UserAgreement = () => {
    const { language, changeLanguage } = useLanguage();

    return (
        <div className="container mx-auto px-4 py-2">
            <button
          className="absolute top-0 right-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4"
          onClick={() => changeLanguage(language === 'en' ? 'tr' : 'en')}>
          {language === 'en' ? 'TR' : 'EN'}
        </button>
        <div className="pt-16 w-full">
            <h1 className="text-xl font-semibold mb-4">{language === 'en' ? 'User Agreement and Privacy Policy' : 'Kullanıcı Sözleşmesi ve Gizlilik Politikası'}</h1>
            <p>
                {language === 'en' ? 
                "INFORMATION TEXT UNDER THE PERSONAL DATA PROTECTION LAW, LOYALTY CARD MEMBERSHIP/APPROVAL TEXT R.SIMGE GÜLERYÜZ YEŞILYURT (HYGGE SKIN ART, referred to as HYGGE) will process the personal data of our customers, potential customers, and visitors under the Personal Data Protection Law No. 6698 as a data controller, as stated in this text, and may transfer it to third parties when permitted by legislation. With this information text, we inform you and request your consent (the exception for customers is specified below) and your approval for sending electronic commercial messages to you as specified below." :
                "KİŞİSEL VERİLERİN KORUNMASI KANUNU ÇERÇEVESİNDE BİLGİLENDİRME METNİ, SADAKAT KART ÜYELİK/ONAY METNİ R.SİMGE GÜLERYÜZ YEŞİLYURT (HYGGE SKIN ART  (HYGGE olarak anılacaktır) olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında veri sorumlusu olarak müşterilerimizin, potansiyel müşterilerimizin ve ziyaretçilerimizin kişisel verilerini bu metin içerisinde belirtilen koşullarla işleyecek ve mevzuatın izin verdiği durumlarda üçüncü kişilere aktarabileceğiz. İşbu aydınlatma metni ile sizleri bilgilendiriyor ve aşağıda belirtilen kişisel verileriniz için ve yine aşağıda belirtilen şekilde rızanızı (Müşteriler için istisna aşağıda belirtilmiştir.) ve tarafınıza elektronik ticari ileti gönderilebilmesi için onayınızı talep ediyoruz."}
            </p>
            <p>
                {language === 'en' ? 
                "Purpose, Legal Reason, and Processed Personal Data Your personal information; during your visits to our studios and website, through short messages sent during wheel spinning or appointment processes, and direct collection from you electronically as per forms processed into our systems. In case of issuing invoices due to wheel spinning or appointments made via HYGGE's Mobile Application or website, or in case you accept membership or fill out a form on the website, these data including identity information (Name, Surname, Gender, Date of Birth); contact data (Email address, Phone details, Address), information related to you, and visual data reflected on security cameras in the store; will be processed by HYGGE under Article 5, Paragraph 2 of the Law, for the establishment and performance of purchase/sale contracts, membership contracts, the legal obligation of the data controller, and our legitimate interests, provided that your rights are not harmed. Your personal data are processed for purposes such as Execution of Goods/Service Sales and After-Sales Support Processes, Execution of Loyalty Processes to Our Service, Management of Customer Relations Processes, Execution of Customer Satisfaction Oriented Activities, Ensuring Physical Space Security, Conducting Marketing Analysis Studies, Advertising/Campaign/Promotion Processes and Activities, Compliance with Legislation, Meeting Demands and Complaints, Communication Activities, and Ensuring Information Security." :
                'Kişisel Verilerinizin İşlenme Amacı, Hukuki Sebebi ve İşlenen Kişisel Verileriniz Tarafınıza ait kişisel bilgiler; stüdyolarımızı ve web sitemizi ziyaretiniz, çark çevirme veya randevu süreçleri sırasında sizlere gönderilen kısa mesaj ve form içinde yer alan açıklamalar ve form uyarınca elektronik ortamda doğrudan sizlerden alınarak sistemlerimize işlenmektedir HYGGE , Mobil Uygulamasından veya web sitesinden yaptığınız çark çevirme veya randevu nedeniyle fatura kesilmesi veya web sitesi üyeliğini veya form doldurmanızda kabul etmeniz durumunda  bu verilerinizden; kimlik bilgileri (Ad, Soyad, Cinsiyet, Doğum tarihi); iletişim verileri (E-mail adresi, Telefon bilgileri, Adres), size ait bilgiler ve mağaza içinde güvenlik kameralarına yansıyan görsel veriler; HYGGE tarafından, Kanunun 5. Maddesi 2 Fıkrası uyarınca alım satım sözleşmenin kurulması, ifası, üyelik sözleşmesinin kurulması ve ifası, veri sorumlusunun hukuki yükümlülüğü ve haklarınıza zarar vermemek koşuluyla meşru menfaatlerimiz kapsamında işlenecektir. Kişisel verileriniz ; Mal/Hizmet Satış ve Satış Sonrası Destek Süreçlerinin Yürütülmesi, Hizmetimize Bağlılık Süreçlerinin Yürütülmesi, Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi ,Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Fiziksel Mekan Güvenliğinin Temini, Pazarlama Analiz Çalışmalarının Yürütülmesi, Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi ve Faaliyetlerin mevzuata uygun yürütülmesi, Talep ve Şikayetlerin karşılanması, İletişim Faaliyetleri ve Bilgi güvenliğinin sağlanması gibi amaçlarla işlenmektedir'}
            </p>
            <h2 className="text-lg font-semibold mt-4">{language === 'en' ? 'Transfer of Personal Data' : ' Kişisel Verilerin Aktarımı'}</h2>
            <p>
                {language === 'en' ? 
                'Your personal data specified herein by HYGGE, provided that adequate and effective measures are taken in accordance with the security and confidentiality principles determined in the legislation; To public institutions or organizations authorized to request this data in accordance with legal obligation, to our business partners and suppliers with whom we cooperate to deliver our products and services to you, (Cargo for the delivery of products, Call for customer relations) such data may be transferred in accordance with Article 8 of the Law, based on the processing reasons stated above.' :
                'HYGGE  tarafından burada belirtilen kişisel verileriniz, mevzuatta belirlenen güvenlik ve gizlilik esasları uyarınca yeterli ve etkili önlemler alınmak kaydıyla; yasal zorunluluk gereği bu verileri talep etmeye yetkili olan kamu kurum veya kuruluşlarına, markamızın bünyesinde bulunduğu R.SİMGE GÜLERYÜZ YEŞİLYURT (HYGGE SKIN ART sizlere ürün ve hizmetlerimizi ulaştırmak için işbirliği içinde bulunduğumuz iş ortaklarımıza, tedarikçilerimize, (Ürünlerin teslimi için Kargo, Müşteri ilişkileri için çağrı merkezi gibi) işbu verilerin yukarıda belirtilen işleme sebepleri nezdinde Kanunun 8.maddesi uyarınca aktarılabilecektir.'}
            </p>

            <h2 className="text-lg font-semibold mt-4">{language === 'en' ? 'Consent and Permission Method for Processing Your Personal Data' : 'Kişisel Verilerinizin İşlenmesi İçin Rıza ve İzin Yöntemi'}</h2>
            <p>
                {language === 'en' ? 
                'If you have found the matters in the Information Text link and the matters stated below for loyalty card membership appropriate for the processing of these personal data presented to your information in the digital environment within the SMS text, your permission-approval process will be completed if you forward the codes sent separately for each transaction in the same SMS to our store cashier personnel. . Then, a registration information message will be sent to your GSM line.' :
                'Dijital ortamda, SMS metni içinde bilginize sunulan işbu kişisel verilerin işlenmesi Aydınlatma Metni linkindeki hususları ve aşağıda sadakat kart üyeliği için belirtilen hususları uygun buldu iseniz aynı SMS içinde her bir işlem için ayrı ayrı gönderilen kodları, mağaza kasa personelimize iletmeniz durumunda izin-onay işleminiz tamamlanmış olacaktır. Daha sonra GSM hattınıza kayıt bilgi mesajı gelecektir.'}
            </p>

            <h2 className="text-lg font-semibold mt-4">{language === 'en' ? 'Your Rights Under the Law' : 'Kanun Kapsamındaki Haklarınız'}</h2>
            <p>
                {language === 'en' ?
                'As personal data subjects, you have the right to learn whether personal data is being processed, to request information if personal data has been processed, to learn the processing purpose and whether it is used accordingly, to know third parties to whom personal data is transferred domestically or internationally, to request the correction of personal data if processed incompletely or inaccurately and to request that this correction is notified to third parties to whom personal data has been transferred, to request the deletion or destruction of personal data in case the reasons necessitating their processing cease to exist despite being processed in accordance with the Law No. 6698 and other relevant laws, and to be informed about this process, to object to an outcome that emerges solely through the analysis of processed data by automated systems, and to claim damages in case of damage due to unlawful processing of personal data. You can access the details from the Personal Data Processing Policy available at the web address www.manilabeat.com, and you can submit your requests by post or in person to our company address or to the email address info@hyggeskinart.com, through registered electronic mail (KEP) address, secure electronic signature, mobile signature, or the email address previously notified to HYGGE, and depending on the nature of your request, it will be finalized as soon as possible and within thirty days at the latest. HYGGE SKIN ART (DATA CONTROLLER) Address: Kazımdirik mah. Sanayi cad. 17/I Phone: +90 (533) 719 46 78 Web Address: www.hyggeskinart.com' :
                'Kişisel veri sahibi ilgili kişiler olarak, kişisel veri işlenip işlenmediğini öğrenme, kişisel verileri işlenmişse buna ilişkin bilgi talep etme, kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, 6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahipsiniz. www.manilabeat.com web adresinde mevcut Kişisel Verilerin İşlenmesi Politikasından detaylara ulaşabilir, taleplerinizi yazılı olarak posta yoluyla veya elden şirket adresimize veya info@hyggeskinart.com e-posta adresine, kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da tarafınızca HYGGE’ye daha önce bildirilen elektronik posta adresini kullanmak suretiyle iletmeniz durumunda talebin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde sonuçlandıracaktır. HYGGE SKIN ART (VERİ SORUMLUSU) Adres: Kazımdirik mah. Sanayi cad. 17/I Telefon: +90 (533) 719 46 78 Web Adresi: www.hyggeskinart.com'}
            </p>

            <h2 className="text-lg font-semibold mt-4">{language === 'en' ? 'Commercial Electronic Communication Permission' : 'Ticari Elektronik İletişim İzni'}</h2>
            <p>
                {language === 'en' ?
                'By processing my CONTACT data, HYGGE will send commercial electronic messages and other messages to the communication addresses I provided during shopping/visiting processes and subsequently in various ways (including my cell phone, email address, and others) for all kinds of shopping transactions and applications, marketing activities, both for announcing various product-service opportunities and for advertising and promotion, and I hereby give my consent for sending these messages in compliance with the relevant laws, and I can stop the communication by performing the action (Reject) specified in the SMS/email messages coming to me (or in the system established/mandated by the authorized authorities if available), and all other related matters are informed hereby.' :
                'HYGGE tarafından İLETİŞİM verilerimin işlenmesi suretiyle,(alışveriş/ziyaret işlemleri sırasında verdiğim ve bilahare muhtelif suretler ile vereceğim iletişim adreslerime (cep telefonu, e-mail adresim ve diğerleri de dahil olmak üzere)her türlü alışveriş işlemleri ve uygulamaları, pazarlama faaliyetleri, gerek çeşitli ürün-hizmetlerin ve genel/özel imkanların duyurulması, tanıtım ve reklamının yapılması için; tarafıma ilgili kanunlara uygun olarak ticari elektronik iletiler ve diğer iletiler gönderileceği, hiçbir gerekçe göstermeksizin tarafıma gelen SMS/e-mail mesajlarında (ya da varsa yetkili mercilerce kurulan/kurdurulan sistemde) belirtilen işlemi (Red) yaparak iletişimi durdurabileceğimi (kanunlar gereği mümkün/gerekli iletişimlerin her durumda devam edeceği) ve diğer tüm ilgili hususlarda buradaki açıklamalar ile bilgilenmiş olarak her türlü ürün ve hizmetlere ilişkin çeşitli bilgilendirme, tanıtım, reklam, promosyon, satış ve pazarlama amacıyla, keza, üyelik-alışveriş bilgilendirmeleri, işlem ve uygulamaları için Şirketinizce kanunlara uygun şekilde SMS/kısa mesaj, anlık bildirim, otomatik arama, telefonla arama, bilgisayar, telefon, internet, sosyal medya ile online reklam ağları, bluetooth-beacon dahil kablosuz genel-özel bağlantılar ve ağlar, faks, e-posta/mail, her türlü bildirimler ve diğer elektronik iletişim araçları-kanalları ile tarafıma ticari elektronik iletiler ve diğer iletiler gönderilmesine, onay veriyorum.'}
            </p>
            <h2 className="text-lg font-semibold mt-4">{language === 'en' ? 'Note:' : 'Bilgi Notu:'}</h2>
            <p>
                {language === 'en' ?
                'According to the Law No. 6563 on the Regulation of Electronic Commerce; records of withdrawal of consent will be kept for 1 year from this date, and all other records related to the content of the commercial electronic message and the shipment will be kept for 3 years to be presented to the relevant ministry when required. After the period has passed, these data will be destroyed by HYGGE or upon your request.' :
                '6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca; onayın geri alındığına ilişkin kayıtlar bu tarihten itibaren 1 yıl; ticari elektronik iletinin içeriği ve gönderiye ilişkin diğer her türlü kayıt ise gerektiğinde ilgili bakanlığa sunulmak üzere 3 yıl saklanacaktır. Süre geçtikten sonra bu veriler HYGGE tarafından veya talebiniz üzerine imha edilir.'}
            </p>
            
            </div>
        </div>
    );
};

export default UserAgreement;
