import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, query, where, collection, getDocs } from "firebase/firestore";
import {emailReg, passwordRegExp, nameRegExp} from '../../utils/validators';
import Person from '../../models/Person';
import { useLanguage } from './../../localization/LanguageContext';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line
import { setUser } from '../../redux/userSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDay, setBirthDay] = useState(new Date().toISOString().split('T')[0]);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [userExistsError, setUserExistsError] = useState('');
  const [phoneNumberExistsError, setPhoneNumberExistsError] = useState('');
  const [ageError, setAgeError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const passwordRef = useRef(null);
  const fullNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const birthDayRef = useRef(null);
  const agreementRef = useRef(null);

    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    if (value) {
        const phoneNumberObject = parsePhoneNumberFromString(value);
        if (phoneNumberObject) {
            setCountryCode(phoneNumberObject.country || '');
            if (phoneNumberObject.isValid()) {
                setPhoneNumberError('');
            } else {
                setPhoneNumberError(language === 'en' ? 'Invalid phone number format or incorrect length for the selected country.' : 'Telefon numarası formatı geçersiz veya seçilen ülke için uzunluk yanlış.');
            }
        } else {
            setCountryCode('');
            setPhoneNumberError(language === 'en' ? 'Invalid phone number format.' : 'Telefon numarası formatı geçersiz.');
        }
    } else {
        setCountryCode('');
        setPhoneNumberError(language === 'en' ? 'Phone number cannot be empty.' : 'Telefon numarası boş bırakılamaz.');
    }
};

  const checkExistingEmail = async (email) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if user exists
  };

  const checkExistingPhoneNumber = async (phoneNumber) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if user exists
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 0'dan başlar
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
  const handleRegister = async (e) => {
    e.preventDefault();
    let isValid = true;

    setError('');
    setEmailError('');
    setPasswordError('');
    setFullNameError('');
    setPhoneNumberError('');
    setUserExistsError('');
    setPhoneNumberExistsError('');
    setAgeError('');

    // Check age requirement
    const age = calculateAge(birthDay);

    if (age < 18) {
      setAgeError(language === 'en' ? 'You must be at least 18 years old to register.' : 'Kayıt olmak için en az 18 yaşında olmalısınız.');
      isValid = false;
    }

    const emailExists = await checkExistingEmail(email);
    const phoneNumberExists = await checkExistingPhoneNumber(phoneNumber);

    if (emailExists) {
      setEmailError(language === 'en' ? 'This email is already in use.' : 'Bu mail adresi ile kullanıcı bulunmaktadır.');
      isValid = false;
    }

    if (phoneNumberExists) {
      setPhoneNumberExistsError(language === 'en' ? 'This phone number is already in use.' : 'Bu numarada kullanıcı bulunmaktadır.');
      isValid = false;
    }

    if (!agreed) {
      setError(language === 'en' ? 'You must agree to the terms to register and participate in the campaign.' : 'Kayıt olabilmek ve kampanyadan faydalanabilmek için kullanıcı sözleşmesini kabul etmelisiniz.');
      isValid = false;
    }

    if (!email) {
      setEmailError(language === 'en' ? 'E-mail cannot be empty.' : 'E-posta boş bırakılamaz.');
      isValid = false;
    } else if (!emailReg.test(email)) {
      setEmailError(language === 'en' ? 'Invalid e-mail address.' : 'Geçersiz e-posta adresi.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!passwordRegExp.test(password) || password.length < 6) {
      setPasswordError(language === 'en' ? 'Password must be at least 6 characters and contain at least one number and one uppercase letter.' : 'Şifre en az 6 karakter olmalı ve en az bir sayı ve bir büyük harf içermelidir.');
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (!fullName) {
      setFullNameError(language === 'en' ? 'Name cannot be empty.' : 'İsim boş bırakılamaz.');
      isValid = false;
    } else if (!nameRegExp.test(fullName)) {
      setFullNameError(language === 'en' ? 'Invalid name format.' : 'Geçersiz isim formatı.');
      isValid = false;
    } else {
      setFullNameError('');
    }
    
   /*  if (!phoneNumber || !isValidPhoneNumber(phoneNumber, countryCode)) {
      setPhoneNumberError(language === 'en' ? 'Invalid or incorrect length of phone number for selected country.' : 'Seçilen ülke için geçersiz veya yanlış telefon numarası uzunluğu.');
      isValid = false;
    } */
    if (!phoneNumber) {
      setPhoneNumberError(language === 'en' ? 'Phone number cannot be empty.' : 'Telefon numarası boş bırakılamaz.');
      isValid = false;
    }

    if (isValid) {
       try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          const createdAt = new Date();
          const formattedBirthDay = birthDay.split('-').reverse().join('.');
          const newPerson = new Person(user.uid, email, fullName, phoneNumber, formattedBirthDay, [], createdAt, false, countryCode, 1);
          await setDoc(doc(db, "users", user.uid), {
              id: user.uid,
              email: newPerson.email,
              fullName: newPerson.fullName,
              phoneNumber: newPerson.phoneNumber,
              birthDay: newPerson.birthDay,
              spins: newPerson.spins,
              createdAt: formatDate(newPerson.createdAt),
              isAdmin: newPerson.isAdmin,
              countryCode: newPerson.countryCode,
              spinCount: newPerson.spinCount,
          });
          dispatch(setUser({...user, ...newPerson}));
          navigate('/spin-wheel');
      } catch (error) {
        setError(language === 'en' ? 'An error occurred during registration:' : 'Kayıt sırasında bir hata oluştu:' + error.message);
      }
    }
  };

  const handleKeyDown = (event, focusRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      focusRef.current && focusRef.current.focus();
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full pt-10 max-w-md px-6 py-8">
      <button 
          onClick={() => navigate(-1)} 
          className="absolute top-0 left-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4"
        >
         {language === 'en' ? 'Geri Git' : 'Go Back'}
        </button>
        <button className="absolute top-0 right-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4" onClick={() => changeLanguage(language === 'en' ? 'tr' : 'en')}>
          {language === 'en' ? 'TR' : 'EN'}
        </button>
        <form onSubmit={handleRegister} className="space-y-3">
        <p className="text-gray-700 text-xs  mb-1">{language === 'en' ? 'E-mail' : 'E-posta'}</p>
          <input 
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            type="email" 
            value={email} onChange={(e) => setEmail(e.target.value)} 
            // placeholder={language === 'en' ? 'E-mail' : 'E-posta'}
            placeholder={'hygge@mail.com'}
            onKeyDown={(e) => handleKeyDown(e, passwordRef)}
          />
          {userExistsError && <p className="text-red-500 text-xs italic">{userExistsError}</p>}
          {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
          <p className="text-gray-700 text-xs  mb-1">{language === 'en' ? 'Password' : 'Şifre'}</p>
           <div className="relative">
              <input
              ref={passwordRef}
                className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
                type={passwordShown ? 'text' : 'password'}
                placeholder={'**********'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, fullNameRef)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 text-gray-700 flex items-center text-sm leading-5"
                onClick={togglePasswordVisibility}
              >
                {passwordShown ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
            <p className="text-gray-700 text-xs  mb-1">{language === 'en' ? 'Name Surname' : 'İsim Soyisim'}</p>
          <input 
            ref={fullNameRef}
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            type="text" 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)} 
            placeholder={language === 'en' ? 'Name Surname' : 'İsim Soyisim'}
            onKeyDown={(e) => handleKeyDown(e, phoneNumberRef)}
          />
          {fullNameError && <p className="text-red-500 text-xs italic">{fullNameError}</p>}
          <p className="text-gray-700 text-xs  mb-1">{language === 'en' ? 'Phone number' : 'Telefon Numarası'}</p>
           <PhoneInput
            ref={phoneNumberRef}
            international
            defaultCountry="TR"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            placeholder={'XXX XXX XX XX'}
            onKeyDown={(e) => handleKeyDown(e, birthDayRef)}
          />
          {phoneNumberExistsError && <p className="text-red-500 text-xs italic">{phoneNumberExistsError}</p>}
          {phoneNumberError && <p className="text-red-500 text-xs italic">{phoneNumberError}</p>}
          <p className="text-gray-700 text-xs  mb-1">{language === 'en' ? 'Birth date' : 'Doğum tarihi'}</p>
          <input
            ref={birthDayRef}
            type="date"
            value={birthDay}
            onChange={(e) => setBirthDay(e.target.value)}
            className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
            onKeyDown={(e) => handleKeyDown(e, agreementRef)}
          />
                    {ageError && <p className="text-red-500 text-xs italic">{ageError}</p>}
          <div className="flex items-center px-2 py-2">
              <input
               ref={agreementRef}
                type="checkbox"
                id="agreement"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                className="opacity-0 absolute h-8 w-8"
                onKeyDown={(e) => handleKeyDown(e, handleRegister(new Event('submit')))}
              />
              <label
                htmlFor="agreement"
                className="flex items-center cursor-pointer text-xs text-gray-700"
              >
                <span
                  className="inline-block mr-2 rounded-sm border border-black bg-white w-4 h-4"
                  style={{
                    backgroundColor: agreed ? 'black' : 'white',
                    border: '1px solid black'
                  }}
                ></span>
               <label htmlFor="agreement" className="text-gray-700">
                      {language === 'en' ? 'I agree to the ' : ''}
                    <a href="/user-agreement" target='_blank' className="underline text-black hover:text-black-800 visited:text-purple-600">
                        {language === 'en' ? 'User Agreement and Privacy Policy' : 'Kullanıcı Sözleşmesi ve Gizlilik Sözleşmesi'}
                    </a>
                    {language === 'en' ? '' : ' ni kabul ediyorum.'}
                  </label>
              </label>
            </div>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
            <button type="submit" className="w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline">{language === 'en' ? 'Register' :'Kayıt Ol' }</button>
        </form>
      </div>
    </div>
  );
};

export default Register;