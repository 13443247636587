import React, { useState } from 'react';
import { auth, db } from '../../firebase/firebase';
import { query, where, collection, getDocs } from "firebase/firestore";
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../localization/LanguageContext';
import { emailReg } from '../../utils/validators';
import hyggeLogo from '../../assets/images/hyggeLogo.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();

  const checkExistingEmail = async (email) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError('');

    if (!emailReg.test(email)) {
      setEmailError(language === 'en' ? 'Invalid e-mail address.' : 'Geçersiz e-posta adresi.');
      return;
    }

    const emailExists = await checkExistingEmail(email);
    if (!emailExists) {
      setEmailError(language === 'en' ? 'There is no user with this e-mail address.' : 'Bu mail adresi ile kullanıcı bulunmamaktadır.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert(language === 'en' ? 'Password reset link sent! Check your email.' : 'Şifre sıfırlama bağlantısı gönderildi! E-postanızı kontrol edin.');
      navigate('/login');
    } catch (error) {
      setEmailError(language === 'en' ? error.message : 'Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-md px-6 py-8">
        <img className="mx-auto mb-4" src={hyggeLogo} alt="Hygge Logo" />
        <button 
          onClick={() => navigate(-1)} 
          className="absolute top-0 left-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4"
        >
          {language === 'en' ? 'Go Back' : 'Geri Git'}
        </button>
        <button 
          className="absolute top-0 right-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4" 
          onClick={() => changeLanguage(language === 'en' ? 'tr' : 'en')}
        >
          {language === 'en' ? 'TR' : 'EN'}
        </button>
        <div className="w-full flex flex-col items-center">
        <p className="text-gray-700 text-xs mb-1 self-start">
            {language === 'en' ? 'E-mail' : 'E-posta'}
          </p>          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <input
              className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
              type="email"
              placeholder={'hygge@mail.com'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
            <button
              className="w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {language === 'en' ? 'Send Reset Link' : 'Sıfırlama Bağlantısı Gönder'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
