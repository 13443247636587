import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLanguage } from './../../localization/LanguageContext';
import i18n from './../../localization/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Spin from '../../models/Spin';
import { clearUser } from '../../redux/userSlice';
import hyggeLogo from '../../assets/images/hyggeLogo.png';

const SpinWheel = () => {
  const [items, setItems] = useState([]);
  const [rotateDegree, setRotateDegree] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const auth = getAuth();
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();
  const radius = 190;
  const [user, setUser] = useState({
    fullName: '',
    phoneNumber: '',
    spins: [],
  });
  

   useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, "wheel_items"));
      const itemList = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setItems(itemList);
    };

    fetchItems();
    
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser({
            ...userData,
            spins: userData.spins || [],
            spinCount: userData.spinCount || 0
        });
        }
      } else {
        console.log("Kullanıcı giriş yapmamış veya çıkış yapmış.");
      }
    });
    const storedLang = localStorage.getItem('i18nextLng') || 'en';
    if (i18n.language !== storedLang) {
      changeLanguage(storedLang);
    }
  }, [navigate, changeLanguage]);

const spinWheel = async () => {
  if (!userId || !auth.currentUser) {
    console.log("No user is logged in.");
    return;
  }
  if (spinning || !items.length || user.spinCount <= 0 || isNaN(user.spinCount)) {
    console.log("Cannot spin anymore.");
    return;
  }
  setSpinning(true);
  const spinDegrees = 3600 + Math.floor(Math.random() * 360);
  setRotateDegree(prev => prev + spinDegrees);

  setTimeout(async () => {
    const weightedList = items.flatMap(item => Array(item.chance).fill(item));
    const randomIndex = Math.floor(Math.random() * weightedList.length);
    const result = weightedList[randomIndex];
    setSpinning(false);
 
    const now = new Date();
    const durationDays = result.duration || 1;
    const expiredAt = new Date(now);
    expiredAt.setDate(now.getDate() + durationDays);

    const newSpin = new Spin(formatDate(now), formatDate(expiredAt), result.itemName, result.itemNameEn);
    const updatedSpins = [...user.spins, newSpin];
    const newSpinCount = user.spinCount - 1;
    updateUserWithNewSpin(updatedSpins, newSpinCount);
  }, 5000);
};

const updateUserWithNewSpin = async (spins, newSpinCount) => {
    const userDocRef = doc(db, "users", userId);
    const formattedSpins = spins.map(spin => ({
      createdAt: spin.createdAt instanceof Date ? formatDate(spin.createdAt) : spin.createdAt,
      expiredAt: spin.expiredAt instanceof Date ? formatDate(spin.expiredAt) : spin.expiredAt,
      resultName: spin.resultName,
      resultNameEn: spin.resultNameEn,
  }));

  await updateDoc(userDocRef, {
      spins: formattedSpins,
      spinCount: newSpinCount,
  });
    setUser(prev => ({ ...prev, spins: formattedSpins, spinCount: newSpinCount }));
};

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

  const handleSignOut = () => {
    signOut(auth).then(() => {
      dispatch(clearUser());
      navigate('/login');
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  const generateWhatsAppMessageUrl = async (spin, index) => {
    const phoneDoc = doc(db, "settings", "phoneConfig");
    const docSnap = await getDoc(phoneDoc);
    if (docSnap.exists()) {
      const phone = docSnap.data().phoneNumber;
      const ordinal = (index + 1) + (language === 'en' ? 'st' : '.');
      const messageEnglish = `Hello, I am ${user.fullName}, my phone number is ${user.phoneNumber}, and my ${ordinal} spin result is:${spin.resultNameEn}.`;
    const messageTurkish = `Merhaba, ben ${user.fullName}, telefon numaram ${user.phoneNumber}, ve ${ordinal} çark sonucum: ${spin.resultName}.`;
    const message = language === 'en' ? messageEnglish : messageTurkish;
      const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
       window.location.href = whatsappUrl;
    } else {
      console.log("No phone number found!");
    }
  };


  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white"> {/* border-4 border-black */}
      <div className="w-full max-w-md px-6 py-8 "> {/* border-8 border-black */}
        <button className="absolute top-0 right-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4" onClick={() => changeLanguage(language === 'en' ? 'tr' : 'en')}>
         {language === 'en' ? 'TR' : 'EN'}
        </button>
        <div className="w-full flex flex-col items-center">
        <img className="mx-auto w-1/2 md:w-3/4" src={hyggeLogo} alt="Hygge Logo"/>
          <h1>{language === 'en' ? 'Spins Remaining: ' : 'Kalan Çevirme Hakkı: '}{user.spinCount}</h1>
          <svg viewBox="0 0 400 400" width="300" height="300" className="w-full h-auto max-w-sm sm:max-w-md md:max-w-lg" style={{ transform: `rotate(${rotateDegree}deg)`, transition: 'transform 5s ease-out' }}  onClick={!spinning ? spinWheel : undefined}/* onClick={spinWheel} */>
          {items.map((item, index, arr) => {
            const angle = (360 / arr.length) * index;
            const nextAngle = (360 / arr.length) * (index + 1);
            const isBlack = index % 2 === 0;
            const textRadius = radius * 0.75;  // Text radius adjusted
            const midAngle = angle + (nextAngle - angle) / 2;
            const textX = 200 + textRadius * Math.cos(Math.PI * midAngle / 180);
            const textY = 200 + textRadius * Math.sin(Math.PI * midAngle / 180);

            return (
              <g key={index}>
                <path d={`M200,200 L${200 + radius * Math.cos(Math.PI * angle / 180)},${200 + radius * Math.sin(Math.PI * angle / 180)} A${radius},${radius} 0 0,1 ${200 + radius * Math.cos(Math.PI * nextAngle / 180)},${200 + radius * Math.sin(Math.PI * nextAngle / 180)} Z`}
                  fill={isBlack ? 'black' : 'white'}
                  stroke="black" strokeWidth="2"
                />
                <text
                  x={textX}
                  y={textY}
                  fill={isBlack ? 'white' : 'black'}
                  textAnchor="end"
                  dominantBaseline="central"//central, middle
                  // fontSize="16" 
                  fontSize={radius / 17}
                  transform={`rotate(${midAngle}, ${textX}, ${textY})`}
                  style={{fontWeight: 'bold'}}
                >
                  {language === 'en' ? item.itemNameEn : item.itemName}
                </text>
              </g>
            );
          })}
          <circle cx="200" cy="200" r={radius} stroke="black" strokeWidth="2" fill="none" />
        </svg>
        {user.spins.map((spin, index) => {
   
    function parseDate(dateStr) {
      if (!dateStr) {
        console.error('parseDate was called with undefined or null input');
        return null; // Or return a default date, or handle as needed
      }
      const [date, time] = dateStr.split(' ');
      const [day, month, year] = date.split('.');
      const [hours, minutes] = time.split(':');
      return new Date(year, month - 1, day, hours, minutes);
    }
    const expiredDate = parseDate(spin.expiredAt);
    const now = new Date();
    const isExpired = expiredDate < now;

 return (
    <button
      key={index}
      className={`${isExpired ? "text-white bg-red-500 cursor-not-allowed" : "text-white bg-green-500 hover:bg-green-700"} flex items-center justify-start mt-4 w-full px-4 py-2 border border-black focus:outline-none`}
      onClick={() => !isExpired && generateWhatsAppMessageUrl(spin, index)}
      disabled={isExpired}
    >
      <FontAwesomeIcon icon={!isExpired ? faPaperPlane : undefined} className="mr-2" />
      {language === 'en' ? isExpired ? `Expired At: ${spin.resultNameEn}` : `Send ${index + 1}${getOrdinal(index + 1)} Result: ${spin.resultNameEn}` : isExpired ? ` Günü geçmiş: ${spin.resultName}` :`${index + 1}. Sonuç Gönder: ${spin.resultName}`}
      <br/>
      {language === 'en' ? isExpired ? `Expire Date: ${spin.expiredAt}` : `Valid Until: ${spin.expiredAt}` : isExpired ? `Bittiği tarih: ${spin.expiredAt}` : `Son kullanma tarihi: ${spin.expiredAt}`}
      
    </button>
  );
})}
          {user.spinCount > 0 && !spinning && (
  <button
    className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white"
    onClick={spinWheel}
    disabled={spinning || user.spinCount <= 0}
  >
    {language === 'en' ? 'Spin' : 'Çevir'}
  </button>
)}

          <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={handleSignOut}>{language === 'en' ? 'Log out' :'Çıkış Yap'}</button>
      {handleSignOut}

        </div>
      </div>
    </div>
  );

};

export default SpinWheel;