import { getAuth, signOut } from 'firebase/auth';
import React, { useState, useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase';

const AdminPanel = ({ onOptionsChange }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showShutdownPopup, setShowShutdownPopup] = useState(false);

  useEffect(() => {
    const fetchUserName = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUsername(userSnap.data().fullName); // Firestore'dan alınan kullanıcı adı
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchUserName();
  }, [auth.currentUser]); 

  // Navigate to the User List page
  const navigateToUserList = () => {
    navigate('/user-list');
  };

  // Navigate to the Wheel Edit pagezq
  const navigateToWheelEdit = () => {
    navigate('/edit-wheel');
  };

  const handlePhoneUpdate = async() => {
    const phoneDoc = doc(db, "settings", "phoneConfig");
    await setDoc(phoneDoc, { phoneNumber: phoneNumber });
    setShowPopup(false);
  };

  const showUpdatePopup = async () => {
    setShowPopup(true);
    const phoneDoc = doc(db, "settings", "phoneConfig");
    const docSnap = await getDoc(phoneDoc);
    if (docSnap.exists()) {
      setPhoneNumber(docSnap.data().phoneNumber);
    } else {
      setPhoneNumber('');
    }
  };

  const closeModal = () => {
    setShowPopup(false);
  };

  // Handle user sign out
  const handleSignOut = () => {
    signOut(auth).then(() => {
      // Redirect to login page on successful sign out
      navigate('/login');
    }).catch((error) => {
      // Handle errors
      console.error("Sign out error: ", error);
    });
  };
  
  const handleSiteShutdown = async () => {
    const settingsDoc = doc(db, "settings", "siteConfig");
    await setDoc(settingsDoc, { isSiteOff: true });
    setShowShutdownPopup(false);
  };

  const openShutdownPopup = () => {
    setShowShutdownPopup(true);
  };

  const closeShutdownPopup = () => {
    setShowShutdownPopup(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-md px-6 py-8">
        <p className="text-center text-lg font-bold mb-4">🧿 Admin: {username} 🧿</p>
        <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={navigateToUserList}>Kullanıcı Listesi</button>
        <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={navigateToWheelEdit}>Çark Düzenleme</button>
        <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={showUpdatePopup}>Telefon Numarası Güncelle</button>
        <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={openShutdownPopup}>Siteyi kapat</button>
        <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={handleSignOut}>Çıkış Yap</button>
        {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-bold mb-4">Telefon Numarası Güncelle</h3>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Telefon numarası girin"
              className="border border-gray-300 p-2 mb-4 w-full"
            />
            <div className="flex flex-col">
              <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={handlePhoneUpdate}>
                Kaydet
              </button>
              <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={closeModal}>
                İptal
              </button>
            </div>
          </div>
        </div>
      )}
      {showShutdownPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-lg font-bold mb-4">Siteyi Kapatma Onayı</h3>
              <p>Siteyi kapatmak istediğinizden emin misiniz?</p>
              <div className="flex flex-col">
                <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={handleSiteShutdown}>Onayla</button>
                <button className="mt-4 w-full px-4 py-2 bg-white text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:shadow-outline" onClick={closeShutdownPopup}>İptal</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
