import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../../firebase/firebase';
import { doc, getDoc, query, where, collection, getDocs } from "firebase/firestore";
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { emailReg, passwordRegExp } from '../../utils/validators';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';
import hyggeLogo from '../../assets/images/hyggeLogo.png';
import { useLanguage } from './../../localization/LanguageContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { language, changeLanguage } = useLanguage();
  const passwordInputRef = useRef(null);


  const interpretFirebaseError = (error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        return language === 'en' ? 'Invalid e-mail address.' : 'Geçersiz e-posta adresi.';
      case 'auth/wrong-password':
      case 'auth/user-not-found':
      case 'auth/invalid-credential':
        return language === 'en' ? 'Incorrect login credentials or account not found.' : 'Giriş bilgileriniz yanlış veya hesap bulunamadı.';
      default:
        return error.message;
    }
  };

  const checkExistingEmail = async (email) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if user exists
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    const emailExists = await checkExistingEmail(email);
    if (!emailExists) {
      setEmailError(language === 'en' ? 'There is no user with this e-mail address.' : 'Bu mail adresi ile kullanıcı bulunmamaktadır.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!emailReg.test(email)) {
      setEmailError(language === 'en' ? 'Invalid e-mail address.' : 'Geçersiz e-posta adresi.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!passwordRegExp.test(password)) {
      setPasswordError(language === 'en' ? 'Password must be at least 6 characters.' : 'Şifre en az 6 karakter olmalıdır.');
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (isValid) {
      await signInWithEmailAndPassword(email, password);
    }
  };
  useEffect(() => {
    const fetchAdditionalUserData = async () => {
      if (user) {
        const userDocRef = doc(db, "users", user.user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
  
        dispatch(setUser({...user.user, ...userData})); 
  
        if (userData.isAdmin) {
          dispatch(setUser({...user.user, isAdmin: userData.isAdmin}));
          navigate('/admin');
        } else {
          dispatch(setUser({...user.user, isAdmin: false}));
          navigate('/spin-wheel');
        }
      }
    };
  
    fetchAdditionalUserData();
  }, [user, navigate, dispatch]);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleEmailKeyDown = (event) => {
    if (event.key === 'Enter') {
      passwordInputRef.current && passwordInputRef.current.focus();
    }
  };

  const handlePasswordKeyDown = async (event) => {
    if (event.key === 'Enter') {
      handleSubmit(new Event('submit'));
    }
  };

   const navigateToRegister = () => {
    navigate('/register');
  };

  const navigateToForgotPassword = () => {
    navigate('/forgot-password');
  };
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-md px-6 py-8">
        <button
          className="absolute top-0 right-0 m-4 bg-white text-black border border-black hover:bg-black hover:text-white font-bold py-2 px-4"
          onClick={() => changeLanguage(language === 'en' ? 'tr' : 'en')}>
          {language === 'en' ? 'TR' : 'EN'}
        </button>
        <div className="w-full flex flex-col items-center">
          <img className="mx-auto mb-4" src={hyggeLogo} alt="Hygge Logo" />
          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <input
              className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
              type="email"
              placeholder={language === 'en' ? 'E-mail' : 'E-posta'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleEmailKeyDown}
              on
            />
            {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
            <div className="relative">
              <input
                ref={passwordInputRef}
                className="block w-full px-4 py-2 text-gray-700 bg-white border focus:border-black focus:ring-black focus:outline-none"
                type={passwordShown ? 'text' : 'password'}
                placeholder={language === 'en' ? 'Password' : 'Şifre'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handlePasswordKeyDown}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 text-gray-700 flex items-center text-sm leading-5"
                onClick={togglePasswordVisibility}
              >
                {passwordShown ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
            <button
              className="w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {language === 'en' ? 'Login' : 'Giriş Yap'}
            </button>
          </form>
          {loading && <p className="text-center mt-4">{language === 'en' ? 'Logging in...' : 'Giriş yapılıyor...'}</p>}
          {/* {error && <p className="text-red-500 text-center mt-4">{error.message}</p>} */}
          {error && <p className="text-red-500 text-center mt-4">{interpretFirebaseError(error)}</p>}
          <button className="mt-4 w-full px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white focus:outline-none focus:shadow-outline" onClick={navigateToRegister}>  {language === 'en' ? 'Register' : 'Kayıt Ol'}</button>
          {/* {showRegister && <Register onClose={() => setShowRegister(false)} />} */}
          <span onClick={navigateToForgotPassword} className="py-2 text-black cursor-pointer underline">
            {language === 'en' ? 'Forgot Password' : 'Şifremi Unuttum'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
