class Spin {
    constructor(createdAt, expiredAt, resultName, resultNameEn) {
      this.createdAt = createdAt;
      this.expiredAt = expiredAt;
      this.resultName = resultName;
      this.resultNameEn = resultNameEn;
    }
  }

export default Spin;
