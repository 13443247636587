import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCcoQXn3AqsgbvFWvj__Azd9nm_z52HkOw",
  authDomain: "hygge-wheel-app.firebaseapp.com",
  projectId: "hygge-wheel-app",
  storageBucket: "hygge-wheel-app.appspot.com",
  messagingSenderId: "858540875432",
  appId: "1:858540875432:web:f56341a8eb9c7433a82c9d",
  measurementId: "G-7NVSJPJRWR"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };